// ---------------------------------------------------------------------
// Global JavaScript
// Authors: Andrew Ross & a little help from my friends
// ---------------------------------------------------------------------

var andrewrossco = andrewrossco || {};

(function($, APP) {

    $(function() {
        APP.App.init();
        APP.Viewport.init();
        //APP.Modal.init();
        APP.Scroller.init();
        APP.Carousel.init();
    });

// ---------------------------------------------------------------------
// Browser and Feature Detection
// ---------------------------------------------------------------------

APP.App = {
    userAgent: undefined,
    $html: undefined,

    init: function() {
        APP.Features = APP.Features || {};
        this.userAgent = navigator.userAgent.toLowerCase();
        this.$html = $('html');
        this.noTouch();
        this.isTouch();
        this.isNewIE();
        this.isIE();

        setTimeout(function(){
            $('body#home').addClass('is-loaded');
        }, 1000);


        var burger = $('.mobile-menu-trigger'),
            mask = $('.screen');
        $('.mobile-menu-trigger, .screen').click(function(){

			if( $('body').hasClass('menu-is-active') ) {
			  	burger.removeClass('is-active');
				mask.fadeOut(300);
			    $('body').removeClass('menu-is-active');
			  } else {
			  	mask.fadeIn(300);
				burger.addClass('is-active');
			    $('body').addClass('menu-is-active');
			  }
		});
    },

    noTouch: function() {
        if ( ! ('ontouchstart' in window) ) {
            APP.Features.noTouch = false;
            this.$html.addClass('noTouch');
            return;
        }
        APP.Features.noTouch = false;
    },

    isTouch: function() {
        if ( 'ontouchstart' in window ) {
            APP.Features.isTouch = false;
            this.$html.addClass('isTouch');
            $('video').remove();
            return;
        }
        APP.Features.isTouch = false;
    },

    isNewIE: function() {
		if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            if(navigator.appVersion.indexOf('Trident') === -1) {
                this.$html.addClass('isEDGE');
            } else {
                this.$html.addClass('isIE isIE11');
            }
            return;
        }
        APP.Features.isNewIE = false;
    },

    isIE: function() {
        if( navigator.appVersion.indexOf("MSIE") !== -1 ) {
            this.$html.addClass('isIE');
            APP.Features.isIE = true;
            return;
        }
        APP.Features.isIE = false;
    }

};



// ---------------------------------------------------------------------
// Detect when an element is in the viewport
// ---------------------------------------------------------------------

APP.Viewport = {

    init: function() {
		$.fn.isOnScreen = function(){
			var elementTop = $(this).offset().top,
				elementBottom = elementTop + $(this).outerHeight(),
				viewportTop = $(window).scrollTop(),
				viewportBottom = viewportTop + $(window).height();
			return elementBottom > viewportTop && elementTop < viewportBottom;
		};

		var items = document.querySelectorAll('*[data-animate-in], *[data-detect-viewport]'),
            paths = $('.draw-in path');

        TweenLite.to(paths, 0, {drawSVG:"0"});

		function detection(el) {
            var hasKids = false;

            if( el.hasClass('draw-in') ){
                hasKids = true;
                var kids = el.find('path');
            }

			if( el.isOnScreen() ){
				if(!el.hasClass('in-view')){
					el.addClass('in-view');

                    if( hasKids == true ){
                        TweenLite.to(kids, 1, {drawSVG:"100%"});
                    }
				}
			} else {
				if(el.hasClass('in-view')){
					el.removeClass('in-view');

                    if( el.hasClass('draw-in') ){
                        if( hasKids == true ){
                            TweenLite.to(kids, 1, {drawSVG:"0"});
                        }
                    }
				}
			}
		}

		$(window).on("resize scroll", function(){
			for(var i = 0; i < items.length; i++) {
				var el = $( items[i] );
				detection(el);
			}
		});

		$(document).ready(function(){
			for(var i = 0; i < items.length; i++) {
				var d = 0,
					el = $( items[i] );
				if( items[i].getAttribute('data-animate-in-delay') ) {
					d = items[i].getAttribute('data-animate-in-delay') / 1000 + 's';
				} else {
					d = 0;
				}
				el.css('transition-delay', d);

				 detection(el);
			}
		});
    }
};



// ---------------------------------------------------------------------
// Modal
// ---------------------------------------------------------------------

APP.Modal = {

    init: function() {
        if( $('.meal') ) {
            this.bind();
        } else {
            return;
        }
    },

    bind: function() {

        $(document).ready(function(){

            var trigger = $('.meal-preview'),
                meal = $('.meal'),
                code,
                target;

            trigger.click(function(e){
                e.preventDefault();

                code = $(this).attr('data-meal');
                target = $('#' + code);

                if( target ){
                    target.addClass('is-active');
                    $('body').addClass('modal-is-active');
                }
            });
        });

        var meal = $('.meal'),
            close = $('.modal__close'),
            next = $('.js-modal-change'),
            code,
            par,
            target;

        close.click(function(e){
            e.preventDefault();
            $(this).parents('.meal').removeClass('is-active');
            $('body').removeClass('modal-is-active');
        });

        next.click(function(e){
            e.preventDefault();

            code = $(this).attr('data-meal');
            par = $(this).parents('.meal');

            target = $('#' + code);


            par.addClass('drop-back');

            if( target ){
                target.addClass('is-active');
                $('body').addClass('modal-is-active');
            } else {
                $('body').removeClass('modal-is-active');
            }

            setTimeout(function(){
                par.removeClass('is-active');
            }, 600);
            setTimeout(function(){
                par.removeClass('drop-back');
            }, 1200);

        });




    }
};



// ---------------------------------------------------------------------
// Scroll
// ---------------------------------------------------------------------

APP.Scroller = {

    init: function() {
        if( document.getElementById("info-drawer") ) {
            this.bind();
        } else {
            return;
        }
    },

    bind: function() {

        var drawer = $('#info-drawer'),
            main = $('#content'),
            b = $('body');

        // window.addEventListener('scroll', function() {
        //     if( slider.hasClass('in-view') )
        // });
        //console.log('here');

        // var d = main.offset().top,
        //     w = $(window),
        //     h = window.innerHeight,
        //     p = false; //passed the top
        //
        // w.resize(function() {
        //     d = main.offset().top;
        // });
        //
        // $(document).ready(function(){
        //     if ( w.scrollTop() >= h ) {
        //         //alert('below');
        //         drawer.hide();
        //         b.removeClass('drawer-is-active');
        //         p = true;
        //     }
        // });
        //
        //
        // w.scroll(function() {
        //     if ( p == false ) {
        //         if ( w.scrollTop() >= d ) {
        //             //console.log('hit');
        //             p = true;
        //             drawer.hide();
        //             b.removeClass('drawer-is-active');
        //             window.scrollTo(0, 0);
        //         }
        //     }
        // });

        $('.drawer-trigger').click(function(e){
            e.preventDefault();
            b.toggleClass('open-drawer');
            //drawer.fadeToggle(600);
            //$(window).scroll();
        });
    }
};



// ---------------------------------------------------------------------
// Carousel
// ---------------------------------------------------------------------

APP.Carousel = {

    init: function() {
        if( $('*[data-drawer-target]') ) {
            this.bind();
        } else {
            return;
        }
    },

    bind: function() {

        var trigger = $('*[data-drawer-target]'),
            b = $('body');

        trigger.click(function(e){
            e.preventDefault();

            var el = $(this),
                target = el.attr('data-drawer-target'),
                target = $("#" + target);

            if( el.hasClass('is-active') ){
                el.removeClass('is-active');
                target.removeClass('is-active').slideUp(600);
            } else {
                el.addClass('is-active');
                target.addClass('is-active').slideDown(600);
            }
        });


    }
};




}(jQuery, andrewrossco));
